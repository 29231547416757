* {
  margin: 0;
  padding: 0;
  border-box: box-sizing;
}
.coming-soon-lg {
  background-image: url("../src/Assets/easyBg.png");
  background-size: cover;
  background-position: center;
}
.coming-soon-sm {
  display: none;
}
@media screen and (max-width: 600px) {
  .coming-soon-lg {
    display: none;
  }
  .coming-soon-sm {
    display: flex;
    background-image: url("../src/Assets/easymblBg.png");
    background-size: cover;
    background-position: center;
  }
}

input:focus {
  outline: none;
  border: none;
}
